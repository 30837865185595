import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'

const CookiePolicy = props => (
  <Layout {...props}>
    <Seo title='Contact' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Cookie Policy"
          subheader="Last updated August 30, 2022"
        />
        <text>This Cookie Policy explains how howdelete.com ("Company", "we", "us", and "our") uses cookies and similar technologies to recognize you when you visit our websites at https://howdelete.com, ("Websites"). It explains what these technologies are and why we use them, as well as your rights to control our use of them.</text><br></br>
        <br></br>
        <text>In some cases we may use cookies to collect personal information, or that becomes personal information if we combine it with other information.</text>
        <h2>What are cookies?</h2>
        <text>Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners in order to make their websites work, or to work more efficiently, as well as to provide reporting information.</text><br></br>
        <br></br>
        <text>Cookies set by the website owner (in this case, howdelete.com) are called "first party cookies". Cookies set by parties other than the website owner are called "third party cookies". Third party cookies enable third party features or functionality to be provided on or through the website (e.g. like advertising, interactive content and analytics). The parties that set these third party cookies can recognize your computer both when it visits the website in question and also when it visits certain other websites.</text>
        <h2>Why do we use cookies?</h2>
        <text>We use first and third party cookies for several reasons. Some cookies are required for technical reasons in order for our Websites to operate, and we refer to these as "essential" or "strictly necessary" cookies. Other cookies also enable us to track and target the interests of our users to enhance the experience on our Online Properties. Third parties serve cookies through our Websites for advertising, analytics and other purposes. This is described in more detail below.</text><br></br>
        <br></br>
        <text>The specific types of first and third party cookies served through our Websites and the purposes they perform are described below (please note that the specific cookies served may vary depending on the specific Online Properties you visit):</text>
        <h2>How can I control cookies?</h2>
        <text>You have the right to decide whether to accept or reject cookies. You can exercise your cookie rights by setting your preferences in the Cookie Consent Manager. The Cookie Consent Manager allows you to select which categories of cookies you accept or reject. Essential cookies cannot be rejected as they are strictly necessary to provide you with services.</text><br></br>
        <br></br>
        <text>The Cookie Consent Manager can be found in the notification banner and on our website. If you choose to reject cookies, you may still use our website though your access to some functionality and areas of our website may be restricted. You may also set or amend your web browser controls to accept or refuse cookies. As the means by which you can refuse cookies through your web browser controls vary from browser-to-browser, you should visit your browser's help menu for more information.</text>
        <h2>Do you serve targeted advertising?</h2>
        <text>Third parties may serve cookies on your computer or mobile device to serve advertising through our Websites. These companies may use information about your visits to this and other websites in order to provide relevant advertisements about goods and services that you may be interested in. They may also employ technology that is used to measure the effectiveness of advertisements. This can be accomplished by them using cookies or web beacons to collect information about your visits to this and other sites in order to provide relevant advertisements about goods and services of potential interest to you. The information collected through this process does not enable us or them to identify your name, contact details or other details that directly identify you unless you choose to provide these.</text>
        <h2>How often will you update this Cookie Policy?</h2>
        <text>We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal or regulatory reasons. Please therefore re-visit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.</text><br></br>
        <br></br>
        <text>The date at the top of this Cookie Policy indicates when it was last updated.</text>
        <h2>Where can I get further information?</h2>
        <text>If you have any questions about our use of cookies or other technologies, please email us at hello@howdelete.com</text>
        <Divider />
      </Main>
      <Sidebar>
        <Commitment />
        <Divider />
        <ContactInfo />
      </Sidebar>
    </Stack>
  </Layout>
)

export default CookiePolicy
